<template>
  <div class="Intro:" :class="appStyle == 'stats' ? '' : 'Intro:background'">
    <div class="Intro:header" ref="jsutPlayer">
      <img src="~@/assets/images/intro/headerLine.png" alt="" />
    </div>
    <div class="Intro:player" ref="jsutPlayer">
      <img src="~@/assets/images/intro/justPlayer.png" alt="" />
    </div>
    <div class="Intro:subtitle">
      <img src="~@/assets/images/intro/Handwrite.png" alt="" />
    </div>
    <div class="Intro:maintitle">
      <img src="~@/assets/images/intro/Maintitle.png" alt="" />
    </div>
    <div class="Intro:player">
      <img src="~@/assets/images/intro/player.png" alt="" />
    </div>
    <div class="Intro:ball">
      <img src="~@/assets/images/intro/ball.png" alt="" />
    </div>
    <router-link to="/login">
      <div class="Intro:cta">
        <img src="~@/assets/images/intro/cta.png" alt="" />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  created() {
    if (this.loggedIn()) {
      // Check if token is still valid
      const userid = {
        userid: this.$store.state.user.user.id,
      };
      this.$store
        .dispatch("checkToken", userid)
        .then((res) => {
          if (res.status != 200) this.$router.push("/login");
          else {
            // Routing Based On Permission
            const permissions = this.$store.state.user.user.permissions;
            if (
              permissions.includes("LIVE_ANALYSIS") &&
              permissions.includes("POST_ANALYSIS")
            ) {
              this.$router.push("/selecttype");
            } else if (permissions.includes("LIVE_ANALYSIS")) {
              this.$router.push("/live");
            } else this.$router.push("/post");
          }
        })
        .catch((err) => {
          console.log(err.response.status);
          this.$router.push({
            name: "login",
            params: { msg: "Unauthorized Access. Please login" },
          });
        });
    }
  },
};
</script>

<style lang="scss">
.Intro\: {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  &background {
    background-image: url("~@/assets/images/intro/bg.jpg");
  }
  &header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    img {
      position: relative;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &cta {
    position: absolute;
    bottom: 150px;
    left: 100px;
  }
  &maintitle {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    transform: translateX(-20%);
    img {
      width: 350px;
    }
  }
  &subtitle {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    transform: translateX(-6%) translateY(-20%);
    img {
      width: 350px;
    }
  }
  &ball {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    transform: translateX(13%);
    img {
      max-width: 100%;
      width: 400px;
      transform: translateX(-18%) translateY(35%);
      // height: 200px;
    }
  }
  &player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(13%);
    img {
      width: 800px;
    }
  }
}
</style>
