var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Intro:",
      class: _vm.appStyle == "stats" ? "" : "Intro:background"
    },
    [
      _c("div", { ref: "jsutPlayer", staticClass: "Intro:header" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/intro/headerLine.png"),
            alt: ""
          }
        })
      ]),
      _c("div", { ref: "jsutPlayer", staticClass: "Intro:player" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/intro/justPlayer.png"),
            alt: ""
          }
        })
      ]),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _c("router-link", { attrs: { to: "/login" } }, [
        _c("div", { staticClass: "Intro:cta" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/intro/cta.png"), alt: "" }
          })
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Intro:subtitle" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/intro/Handwrite.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Intro:maintitle" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/intro/Maintitle.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Intro:player" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/intro/player.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Intro:ball" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/intro/ball.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }